<template>
  <div class="form-group flex justify-content-center align-item-center">
    <custom-file-loader
      ref="photoInput"
      @loading="imageOnLoad"
      :label="label"
      :name="name"
      v-if="!disabled"
    />
    <div
      @click="imageWork"
      class="uploaded-image"
      :class="{ uploaded: previewImage }"
      :style="{
        'background-image': currentImage,
      }"
    ></div>
  </div>
</template>

<script>
import { ref, toRef } from "@vue/reactivity";
import CustomFileLoader from "./CustomFileLoader.vue";
import { computed, inject, onBeforeMount, watch } from "@vue/runtime-core";
import { notify } from "@kyvg/vue3-notification";

export default {
  components: { CustomFileLoader },
  name: "preview-file-loader",
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
    },
    disabled: {
      default: false,
    },
  },
  setup(props, context) {
    const previewImage = ref(null),
      photoInput = ref(null),
      imageUrl = inject("imageUrl");

    const imageTypes = ["image/png", "image/jpeg", "image/jpg", "image/jfif"];
    const onLoadImage = toRef(props, "image"),
      disabledInput = toRef(props, "disabled"),
      currentImage = computed(() =>
        onLoadImage.value && !onLoadImage.value.startsWith("data:image")
          ? `url(${imageUrl}/${onLoadImage.value})`
          : `url(${previewImage.value})`
      );

    const imageOnLoad = (files) => {
      if (files.length && imageTypes.includes(files[0].type)) {
        let reader = new FileReader();
        reader.onload = (e) => {
          previewImage.value = e.target.result;
          context.emit("loadPhoto", previewImage.value);
        };
        reader.readAsDataURL(files[0]);
      } else {
        notify({
          type: "error",
          title: "Неверный формат файла",
        });
      }
    };
    const imageWork = () => {
      if (!disabledInput.value) {
        if (previewImage.value) {
          previewImage.value = null;
          photoInput.value.clearInput();
          context.emit("delete");
        } else {
          photoInput.value.clickOnInput();
        }
      }
    };

    onBeforeMount(() => {
      if (onLoadImage.value) previewImage.value = onLoadImage.value;
    });

    watch(onLoadImage, () => {
      previewImage.value = onLoadImage.value;
    });

    return {
      previewImage,
      photoInput,
      imageOnLoad,
      imageWork,
      onLoadImage,
      currentImage,
      imageUrl,
      disabledInput,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  margin: 15px -15px;
  padding: 5px;
}

.uploaded-image {
  position: relative;
  height: 150px;
  width: 150px;
  background-color: rgb(255, 255, 255);
  border: 7px dotted #ebebeb;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  margin-left: 50px;

  &::before {
    content: "\f03e";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -45px;
    margin-left: -30px;
    font-weight: 200;
    font-family: "Font Awesome 5 Pro";
    color: #ebebeb;
    font-size: 60px;
  }
}

.uploaded-image.uploaded {
  border: 3px solid #2cc185;
  border-radius: 3px;
}

.uploaded-image.uploaded::before {
  content: "";
}

.uploaded-image.uploaded:hover {
  border: 3px solid var(--main-color);

  &::before {
    content: "\f2ed";
    font-size: 60px;
    margin-top: -48px;
    margin-left: -25px;
    opacity: 0.5;

    color: var(--main-color);
  }
}

@media (max-width: 1400px) {
  .uploaded-image {
    margin-left: 35px;
  }
}

@media (max-width: 768px) {
  .uploaded-image.uploaded {
    border: 3px solid var(--main-color);

    &::before {
      content: "\f2ed";
      font-size: 60px;
      margin-top: -48px;
      margin-left: -25px;
      opacity: 0.5;

      color: var(--main-color);
    }
  }
}

@media (max-width: 425px) {
  .form-group {
    flex-direction: column;
    margin-top: 0;
    width: 100%;
  }

  .uploaded-image {
    margin-left: 0;
  }
}
</style>