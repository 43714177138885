<template>
  <div class="view-container">
    <company-form/>
  </div>
</template>

<script>
import CompanyForm from '../../components/Forms/CompanyForm.vue'
export default {
  components: { CompanyForm },
    name: "company"

}
</script>

<style>

</style>